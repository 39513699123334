// src/pages/Home/SystemDemo.tsx
import React from 'react';
import './SystemDemo.scss';
import DemoImage from '../../assets/images/DemoImage.png';

function SystemDemo() {
    return (
        <section className="system-demo">
            <h2>系统截图和演示</h2>
            <div className="demo-content">
                <div className="demo-image">
                    <img src={DemoImage} alt="系统截图" />
                </div>
                <div className="demo-description">
                    <h3>系统截图和演示</h3>
                    <ul>
                        <li>按日、周、月，实时分配工作计划，精细管理到每日工作</li>
                        <li>任务可层层分解，企业重点工作可层层下发落实到每个人</li>
                        <li>提供短视频演示，帮助客户更好地理解系统的使用方法和实际效果</li>
                    </ul>
                    <div className="consult-button">立即咨询</div>
                </div>
            </div>
        </section>
    );
}

export default SystemDemo;
