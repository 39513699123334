// src/components/Header.tsx
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.scss';
import logo from '../assets/images/logo.png';

function Header() {
    const location = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            console.log(111);
            setIsScrolled(window.scrollY !== 0);
        };

        window.addEventListener('scroll', handleScroll);

        // 清除事件监听器
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header >
            <nav className="content">
                <div className="logo">
                    <img src={logo} alt="Logo" />
                </div>
                
            </nav>
        </header>
    );
}

export default Header;
