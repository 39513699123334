// src/pages/Home/IAAIAPSolution.tsx
import React from 'react';
import './IAAIAPSolution.scss';

function IAAIAPSolution() {
    const SolutionData = [
        {
            title: 'IAA与IAP回传解决方案',
            details: [
                { name: '支持多种渠道的IAA和IAP数据回传，帮助企业优化营销策略。' },
                { name: '数据回传支持实时传输和批量传输，保证数据的准确性和时效性。' },
                { name: '灵活的数据格式和接口支持，适应不同平台和分析工具。' }
            ]
        }
    ];

    return (
        <section className="iaa-iap-solution">
            <h2>IAA与IAP回传解决方案</h2>
            <h3>为满足广告主的投放需求和精准数据需求，系统提供全面的IAA和IAP回传解决方案</h3>
            <div className='solution_table'>
                <div className='solution_table_left'>
                    <div className='solution_table_left_list'>IAA与 IAP数据概览</div>
                    <div className='solution_table_left_list'>回传数据指标</div>
                    <div className='solution_table_left_list'>回传数据指标</div>
                    <div className='solution_table_left_list'>回传数据指标</div>
                    <div className='solution_table_left_list'>回传数据指标</div>
                </div>
                <div className='solution_table_right'>
                    <div className='solution_table_right_top'></div>
                    <div className='solution_table_right_bottom'>
                         <div className='solution_table_right_bottom_text1'>oIAA 回传：</div>
                         <div className='solution_table_right_bottom_text2'> 提供用户在广告互动中的详细数据回传，如广告曝光、点击、观看完成率等数据，帮助广告主优化广告效果。</div>
                         <div className='solution_table_right_bottom_text1'>oIAA 回传：</div>
                         <div className='solution_table_right_bottom_text2'> 提供用户在广告互动中的详细数据回传，如广告曝光、点击、观看完成率等数据，帮助广告主优化广告效果。</div>
                      
                    </div>
                </div>
            </div>
        </section>
    );
}

export default IAAIAPSolution;
