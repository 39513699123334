// src/pages/Home/CoreFeatures.tsx
import React from 'react';
import './CoreFeatures.scss';
import CoreFeatures_icon from '../../assets/images/CoreFeatures_icon.png';

function CoreFeatures() {
    const CoreFeaturesData = [
        {
            title: '内容管理系统',
            tips: [
                { name: '支持上传、编辑和管理短剧、小说内容，支持多种格式 (视频、文字、图片、音频等）。' },
                { name: '内容分类和标签功能，便于内容组织和用户快速查找。' },
                { name: '批量管理工具，允许内容大批量上传、发布和更新，适合多内容同时运作。' }
            ]
        },
        {
            title: '用户交互模块',
            tips: [
                { name: '评论、点赞、分享等互动功能，帮助企业打造活跃的用户社区。' },
                { name: '用户反馈和投诉管理模块，让企业可以及时获取用户反馈并进行改进。' },
                { name: 'Q&A交流平台，通过平台分享可以增加内容的传播力度。' }
            ]
        },
        {
            title: '智能推荐算法',
            tips: [
                { name: '个性化推荐算法，依据用户行为数据提供推荐内容，提升用户粘性和留存率。' },
                { name: '支持推荐算法优化，通过AI智能筛选方式，提升推荐精度。' }
            ]
        },
        {
            title: '数据分析与监控',
            tips: [
                { name: '提供数据仪表盘，展示用户留存率、活跃用户数、内容点击量等关键数据指标。' },
                { name: '用户行为数据分析（如点赞、评论、播放等），帮助企业了解用户偏好和需求。' },
                { name: '数据导出功能，企业可获取数据并用于深度分析。' }
            ]
        },
        {
            title: '广告管理系统',
            tips: [
                { name: '支持投放、排期、展示等广告管理需求，灵活适应不同的广告需求。' },
                { name: '广告位置和频率管理，让企业可以根据需求优化广告展示效果。' },
                { name: '广告统计数据实时更新，包括展示次数、点击率、转化率等。' }
            ]
        },
        {
            title: '会员系统和付费内容',
            tips: [
                { name: '支持VIP会员服务，提供会员专享内容、无广告体验、折扣等福利。' },
                { name: '支持多种付费模式（如包月、按次付费等），提升企业的变现能力。' },
                { name: '定制化会员方案，让企业根据用户需求设置独立的会员权益和特权。' }
            ]
        }
    ];

    return (
        <section className="core-features">
            <h2>核心功能</h2>
            <div className="features-list">
                {CoreFeaturesData.map((feature, index) => (
                    <div className="feature" key={index}>
                        <h3>{feature.title}</h3>
                        {feature.tips.map((tip, tipIndex) => (
                            <div className="feature_content" key={tipIndex}>
                                <img src={CoreFeatures_icon} alt="Icon" />
                                <div>{tip.name}</div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </section>
    );
}

export default CoreFeatures;
