// src/pages/Home/HeroSection.tsx
import React from 'react';
import './HeroSection.scss';

function HeroSection() {
    return (
        <section className="hero-section">
            <div className="content">
                <div className='content_section'>
                    <div className='noc'></div>
                    <div className='content_text'>为短剧和小说内容投放</div>
                    <div className='content_text1'>提供一站式解决方案</div>
                    <div className='content_botton'>
                        立即咨询
                    </div>
                </div>
                
            </div>
        </section>
    );
}

export default HeroSection;
