// src/components/Footer.tsx
import React from 'react';
import './Footer.scss';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-info">
                    <p>邮&nbsp;&nbsp;&nbsp;&nbsp;箱：linlm888@163.com</p>
                    <p>官&nbsp;&nbsp;&nbsp;&nbsp;网：linglianma.com</p>
                    <p>地&nbsp;&nbsp;&nbsp;&nbsp;址：湖南省长沙市岳麓区银盆岭街道火炬城C5组团1217室</p>
                </div>
                
            </div>
            <div className="footer-bottom">
                <a href='https://beian.miit.gov.cn'>Copyright © 所有版权归本公司所有 湘ICP备2024092165号-1  </a>
            </div>
        </footer>
    );
}

export default Footer;
