// src/pages/Home/CustomerTestimonials.tsx
import React from 'react';
import './CustomerTestimonials.scss';

function CustomerTestimonials() {
    const testimonials = [
        {
            feedback: [
                "使用系统后，用户活跃度提升了40%，IAP收入增加20%。",
                "系统功能丰富，易操作，大大提高了内容管理效率。"
            ]
        },
        {
            feedback: [
                "系统功能丰富，易操作，大大提高了内容管理效率。",
                "系统功能丰富，易操作，大大提高了内容管理效率。"
            ]
        }
    ];

    return (
        <section className="customer-testimonials">
            <h2>成功客户 + 客户评价</h2>
            <div className="testimonials-list">
                {testimonials.map((testimonial, index) => (
                    <div className={`testimonial-item testimonial_back${index}`} key={index}>
                        <div className='testimonial-left'></div>
                        <div className="testimonial-feedback">
                            <ul>
                                {testimonial.feedback.map((text, i) => (
                                    <li key={i}>{text}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default CustomerTestimonials;
