// src/pages/ContactUs.js
import React from 'react';

function ContactUs() {
  return (
    <div>
      <h1>Contact Us</h1>
      <p>You can reach us at contact@example.com</p>
    </div>
  );
}

export default ContactUs;
