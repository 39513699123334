// src/pages/About/AboutUsIntro.tsx
import React from 'react';
import './AboutUsIntro.scss';


function AboutUsIntro() {
    return (
        <section className="about-us-intro">
            <div className="intro-content">
                
            </div>
          
        </section>
    );
}

export default AboutUsIntro;
