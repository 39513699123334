// src/pages/Home.js
import React from 'react';
import './index.scss'
import HeroSection from './HeroSection';
import CoreFeatures from './CoreFeatures';
import IAAIAPSolution from './IAAIAPSolution';
import ProductAdvantages from './ProductAdvantages';
import SupportServiceGuarantee from './SupportServiceGuarantee';
import SystemDemo from './SystemDemo';
import CustomerTestimonials from './CustomerTestimonials';
import PricingPlans from './PricingPlans';
import CollaborationProcess from './CollaborationProcess';

function Home() {
  return (
    <div className='home'>
        <HeroSection />
        <CoreFeatures />
        <IAAIAPSolution />
        <ProductAdvantages />
        <SupportServiceGuarantee />
        <SystemDemo />
        <CustomerTestimonials />
        {/* <PricingPlans /> */}
        <CollaborationProcess />
    </div>
  );
}

export default Home;
