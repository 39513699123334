// src/pages/Home/ProductAdvantages.tsx
import React from 'react';
import './ProductAdvantages.scss';
import AdvantageIcon1 from '../../assets/images/AdvantageIcon1.png';
import AdvantageIcon2 from '../../assets/images/AdvantageIcon2.png';
import AdvantageIcon3 from '../../assets/images/AdvantageIcon3.png';
import AdvantageIcon4 from '../../assets/images/AdvantageIcon4.png';

function ProductAdvantages() {
    const advantagesData = [
        {
            id: '01',
            title: '高效的用户体验',
            description: '系统优化了内容加载速度，互动响应时间，用户体验更佳。',
            icon: AdvantageIcon1,
            highlighted: true // 标记为高亮
        },
        {
            id: '02',
            title: '灵活的扩展性',
            description: '模块化设计，根据需求自由配置，满足企业不同业务需求。',
            icon: AdvantageIcon2,
           
        },
        {
            id: '03',
            title: '稳定性与兼容性',
            description: '系统在各种设备、浏览器上兼容性高，提供一致的用户体验。',
            icon: AdvantageIcon3
        },
        {
            id: '04',
            title: '数据隐私与安全性',
            description: '数据采取加密传输，符合隐私政策，确保用户广告信息安全。',
            icon: AdvantageIcon4
        }
    ];

    return (
        <section className="product-advantages">
            <h2>产品优势</h2>
            <div className="advantages-list">
                {advantagesData.map((advantage, index) => (
                    <div className={`advantage-item ${advantage.highlighted ? 'highlighted' : ''}`} key={index}>
                        <img src={advantage.icon} alt={`${advantage.title} Icon`} />
                        <h3>{advantage.id}</h3>
                        <h4>{advantage.title}</h4>
                        <p>{advantage.description}</p>
                        {advantage.highlighted && <div className="consult-button">立即咨询</div>}
                    </div>
                ))}
            </div>
        </section>
    );
}

export default ProductAdvantages;
