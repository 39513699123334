// src/pages/AboutUs.js
import React from 'react';
import AboutUsIntro from './AboutUsIntro'
function AboutUs() {
  return (
    <div>
      <AboutUsIntro />
    </div>
  );
}

export default AboutUs;
