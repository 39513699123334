// src/pages/Home/SupportServiceGuarantee.tsx
import React from 'react';
import './SupportServiceGuarantee.scss';
import SupportServiceGuarantee_item from '../../assets/images/SupportServiceGuarantee_item.png'

function SupportServiceGuarantee() {
    const supportItems = [
        {
            title: '7X24小时客服支持',
            description: '为客户在系统使用中的问题提供帮助。',
            highlight: true
        },
        {
            title: '快速上手',
            description: '提供新手培训和系统操作手册，确保客户能够快速上手使用。'
        },
        {
            title: '系统安全',
            description: '系统定期更新和维护，提供最新功能和安全补丁，确保系统稳定。'
        }
    ];

    return (
        <section className="support-service-guarantee">
            <h2>技术支持和服务保障</h2>
            <div className="support-list">
                {supportItems.map((item, index) => (
                    <div className='support-content'>
                        <div className="support-item" key={index}>
                            <h3>{item.title}</h3>
                            <p>{item.description}</p>
                        </div>
                       {index  < 2 &&  <img src={SupportServiceGuarantee_item} alt="" className='SupportServiceGuarantee_item' />}
                    </div>
                ))}
            </div>
        </section>
    );
}

export default SupportServiceGuarantee;
