// src/pages/Home/CollaborationProcess.tsx
import React from 'react';
import './CollaborationProcess.scss';
import processImage from '../../assets/images/processImage.png';

function CollaborationProcess() {
    const steps = [
        {
            number: '1',
            title: '定制化开发',
            description: '根据需求进行功能定制和调试。'
        },
        {
            number: '2',
            title: '定制化开发',
            description: '根据需求进行功能定制和调试。'
        },
        {
            number: '3',
            title: '签约与部署',
            description: '签约后，系统完成部署并上线。'
        },
        {
            number: '4',
            title: '培训和支持',
            description: '上线后提供培训，确保客户能顺利使用系统。'
        }
    ];

    return (
        <section className="collaboration-process">
            <h2>合作流程</h2>
            <div className="process-content">
                <div className="process-image">
                    <img src={processImage} alt="合作流程图" />
                </div>
                <div className="process-steps">
                    {steps.map((step, index) => (
                        <div className="step-item" key={index}>
                            <div className="step-number">{step.number}</div>
                            <div className="step-info">
                                <h3>{step.title}</h3>
                                <p>{step.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default CollaborationProcess;
